<div class="logout-wrapper">
	 <mat-card>
		  <mat-card-title>
			   <h3>Kilépés</h3>
		  </mat-card-title>
		  <mat-card-header>
			   <p>Valóban ki szeretne lépni?</p>
		  </mat-card-header>
		  <mat-card-content>
			   <button mat-raised-button color="primary" class="float-left" (click)="logout()">Kilépés</button>
			   <button mat-raised-button color="primary" class="float-right" (click)="cancel()">Mégsem</button>
		  </mat-card-content>
	 </mat-card>
</div>