<div class="login-wrapper">
	 <mat-card class="loading-wrapper">
		  <div class="loading-shade" *ngIf="loading!=0">
			   <mat-spinner></mat-spinner>
		  </div>
		  <mat-card-header>
			   <img src="assets/gabler_logo.jpg" width="208" height="80" alt="Gabler logo" />
		  </mat-card-header>
		  <mat-card-content>
			   <form class="login-form" #targetForm="ngForm">
					<mat-form-field appearance="standard" [hideRequiredMarker]="true">
						 <mat-label>Felhasználónév</mat-label>
						 <input matInput name="inputUsername" [(ngModel)]="username" required>
					</mat-form-field>

					<mat-form-field appearance="standard" [hideRequiredMarker]="true">
						 <mat-label>Jelszó</mat-label>
						 <input matInput type="password" name="inputPassword" [(ngModel)]="password" required>
					</mat-form-field>

					<div style="clear: both;" class="alert alert-danger" [hidden]="!error">{{ error }}</div>

					<button mat-raised-button color="primary" (click)="login()" [disabled]="!targetForm.valid">Bejelentkezés</button>
			   </form>
		  </mat-card-content>
	 </mat-card>
</div>