import {Component} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Router} from '@angular/router';

import {AuthService} from 'src/app/services/auth.service';

import {UserModel} from 'src/app/components/settings/user/model/user.model';

@Component({
	selector: 'app-login',
	templateUrl: './login.component.html',
	styleUrls: ['./login.component.scss']
})
export class LoginComponent {

	loading: number = 0;
	error: string = null;

	username: string = '';
	password: string = '';

	constructor(
		private authService: AuthService,
		private router: Router
	) {}

	login() {
		this.loading++;
		this.error = null;

		this.authService.login(this.username, this.password).subscribe(
			res => this.handleResponse(res),
			error => this.handleError(error)
		);
	}

	handleError(serverError) {
		this.loading--;
		this.error = serverError.error.error;
	}

	handleResponse(serverResponse) {
		this.loading--;

		if (this.authService.handle(serverResponse.access_token, new UserModel(serverResponse.user))) {
			this.authService.changeAuthStatus(true);
			this.router.navigateByUrl('/feladatok');
		} else {
			this.authService.delToken();
		}
	}

}
