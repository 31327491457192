import {NgModule} from '@angular/core';

import {BrowserModule} from '@angular/platform-browser';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {OwlDateTimeModule, OwlNativeDateTimeModule, OwlDateTimeIntl, OWL_DATE_TIME_LOCALE} from 'ng-pick-datetime';
import {FileUploadModule} from 'ng2-file-upload';
import {FullCalendarModule} from '@fullcalendar/angular';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import {FontAwesomeModule, FaIconLibrary} from '@fortawesome/angular-fontawesome';
import {fas} from '@fortawesome/free-solid-svg-icons';
import {far} from '@fortawesome/free-regular-svg-icons';

import {MatAutocompleteModule} from '@angular/material/autocomplete';
import {MatButtonModule} from '@angular/material/button';
import {MatCardModule} from '@angular/material/card';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatDialogModule} from '@angular/material/dialog';
import {MatDividerModule} from '@angular/material/divider';
import {MatExpansionModule} from '@angular/material/expansion';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatGridListModule} from '@angular/material/grid-list';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatPaginatorModule} from '@angular/material/paginator';
import {MatSelectModule} from '@angular/material/select';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import {MatSortModule} from '@angular/material/sort';
import {MatTableModule} from '@angular/material/table';
import {MatTabsModule} from '@angular/material/tabs';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';

import {autoFocusDirective} from 'src/app/directives/auto-focus.directive';
import {hasPermissionDirective} from 'src/app/directives/has-permission.directive';
import {TableFilterComponent} from 'src/app/table-filter/table-filter.component';

import {CustomPipesModule} from 'src/app/pipes/custompipes.module';

export class CustomOwlIntl extends OwlDateTimeIntl {
	/** A label for the up second button (used by screen readers).  */
	upSecondLabel = 'Add a second';

	/** A label for the down second button (used by screen readers).  */
	downSecondLabel = 'Minus a second';

	/** A label for the up minute button (used by screen readers).  */
	upMinuteLabel = 'Add a minute';

	/** A label for the down minute button (used by screen readers).  */
	downMinuteLabel = 'Minus a minute';

	/** A label for the up hour button (used by screen readers).  */
	upHourLabel = 'Add a hour';

	/** A label for the down hour button (used by screen readers).  */
	downHourLabel = 'Minus a hour';

	/** A label for the previous month button (used by screen readers). */
	prevMonthLabel = 'Previous month';

	/** A label for the next month button (used by screen readers). */
	nextMonthLabel = 'Next month';

	/** A label for the previous year button (used by screen readers). */
	prevYearLabel = 'Previous year';

	/** A label for the next year button (used by screen readers). */
	nextYearLabel = 'Next year';

	/** A label for the previous multi-year button (used by screen readers). */
	prevMultiYearLabel = 'Previous 21 years';

	/** A label for the next multi-year button (used by screen readers). */
	nextMultiYearLabel = 'Next 21 years';

	/** A label for the 'switch to month view' button (used by screen readers). */
	switchToMonthViewLabel = 'Change to month view';

	/** A label for the 'switch to year view' button (used by screen readers). */
	switchToMultiYearViewLabel = 'Choose month and year';

	/** A label for the cancel button */
	cancelBtnLabel = 'Mégsem';

	/** A label for the set button */
	setBtnLabel = 'Rendben';

	/** A label for the range 'from' in picker info */
	rangeFromLabel = 'From';

	/** A label for the range 'to' in picker info */
	rangeToLabel = 'To';

	/** A label for the hour12 button (AM) */
	hour12AMLabel = 'AM';

	/** A label for the hour12 button (PM) */
	hour12PMLabel = 'PM';
};

FullCalendarModule.registerPlugins([ // register FullCalendar plugins
	dayGridPlugin,
	interactionPlugin
]);

@NgModule({
	declarations: [
		autoFocusDirective,
		hasPermissionDirective,
		TableFilterComponent
	],
	providers: [
		{provide: OWL_DATE_TIME_LOCALE, useValue: 'hu'},
		{provide: OwlDateTimeIntl, useClass: CustomOwlIntl},
	],
	imports: [
		FormsModule,
		FontAwesomeModule,

		MatAutocompleteModule,
		MatButtonModule,
		MatCardModule,
		MatCheckboxModule,
		MatDialogModule,
		MatDividerModule,
		MatExpansionModule,
		MatFormFieldModule,
		MatGridListModule,
		MatIconModule,
		MatInputModule,
		MatPaginatorModule,
		MatSelectModule,
		MatSnackBarModule,
		MatSortModule,
		MatTableModule,
		MatTabsModule,
		MatToolbarModule,
		MatTooltipModule,
		MatProgressSpinnerModule,
	],
	exports: [
		BrowserModule,
		FormsModule,
		ReactiveFormsModule,
		HttpClientModule,
		BrowserAnimationsModule,
		OwlDateTimeModule,
		OwlNativeDateTimeModule,
		FileUploadModule,
		FullCalendarModule,
		FontAwesomeModule,

		MatAutocompleteModule,
		MatButtonModule,
		MatCardModule,
		MatCheckboxModule,
		MatDialogModule,
		MatDividerModule,
		MatExpansionModule,
		MatFormFieldModule,
		MatGridListModule,
		MatIconModule,
		MatInputModule,
		MatPaginatorModule,
		MatSelectModule,
		MatSnackBarModule,
		MatSortModule,
		MatTableModule,
		MatTabsModule,
		MatToolbarModule,
		MatTooltipModule,
		MatProgressSpinnerModule,

		autoFocusDirective,
		hasPermissionDirective,
		TableFilterComponent,

		CustomPipesModule
	]
})
export class CommonModules {
	constructor(library: FaIconLibrary) {
		library.addIconPacks(fas, far);
	}
}